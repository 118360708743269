import { MeasurementToolsProps } from '@3plearning/question-components-library';
import { useState } from 'react';

import {
    initialToolsY, rulerYExtraOffset
} from '../measurement-tools.constants';

export const useToolsY = () => {
    const [toolsY, setToolsY] = useState(initialToolsY);
    const checkTools = (tools: MeasurementToolsProps[]) => {
        if (tools.length < 2) {
            setToolsY(initialToolsY);

            return;
        }

        const both = ['ruler', 'protractor'];

        let toolCounter = 0;

        tools.forEach((tool) => {
            both.forEach((staticTool) => {
                if (staticTool === tool.type) {
                    toolCounter = toolCounter + 1;
                }
            });
        });

        if (toolCounter === 2) {
            setToolsY({
                ruler: rulerYExtraOffset,
                protractor: 0,
                image: 0
            });
        } else {
            setToolsY(initialToolsY);
        }
    };

    return {
        checkTools,
        toolsY
    };
};
