import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../app/store/store.types';
import { QuestionSetType } from '../../helpers/enums';
import { getQueryParam } from '../../helpers/utils';
import { Diagnostics } from './diagnostics';
import { QuestionSet } from './question-set';
import { useSingleVariant } from './single-variant.hooks';

export const SingleVariant = () => {
    const variantId = getQueryParam('variantId');
    const questionSetType = getQueryParam('questionSetType');
    const locale = getQueryParam('locale') || 'en-AU';
    const {
        studentState,
        validation,
        isWorkedSolutionActivated
    } = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const {
        getSingleVariant,
        validateSingleVariant
    } = useSingleVariant();

    useEffect(() => {
        getSingleVariant(variantId as string, questionSetType as string, locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const questionSetTypeToggle = () => {
        switch(Number(questionSetType)) {
        case QuestionSetType.questiconActivity: return (
            <QuestionSet
                studentState={studentState}
                validation={validation}
                isWorkedSolutionActivated={isWorkedSolutionActivated}
                validateSingleVariant={validateSingleVariant}
            />
        );
        case QuestionSetType.unitTest:
        case QuestionSetType.diagnostics: return (
            <Diagnostics
                validateSingleVariant={validateSingleVariant}
            />
        );
        default: return (
            <QuestionSet
                studentState={studentState}
                validateSingleVariant={validateSingleVariant}
            />
        );
        }
    };

    return (
        <>
            {
                questionSetTypeToggle()
            }
        </>
    );
};
