export const coordOffset = {
    ruler: {
        x: -200,
        y: 80
    },
    protractor: {
        x: -200,
        y: 80
    },
    image: {
        x: -200,
        y: 80
    }
};

export const rulerYExtraOffset = 240;

export const initialToolsY = {
    ruler: 0,
    protractor: 0,
    image: 0
};
