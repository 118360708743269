import activityGuideSrc from '../../../assets/images/activity-guide.svg';
import audioIconSrc from '../../../assets/images/audioIcon.svg';
import chevronRight from '../../../assets/images/chevron-right.svg';
import closeIcon from '../../../assets/images/close-icon.svg';
import cloudBumpySrc from '../../../assets/images/CloudBumpy.png';
import cloudNarrowSrc from '../../../assets/images/CloudSoftNarrow.png';
import conceptRefreshIcon from '../../../assets/images/concept-refresh.svg';
import attemptCrossSrc from '../../../assets/images/cross.svg';
import fluencyFailFluffy from '../../../assets/images/fluency-fail-fluffy.png';
import fluencyFailMountain from '../../../assets/images/fluency-fail-mountain.png';
import fluencyFailOcean from '../../../assets/images/fluency-fail-ocean.png';
import leftFluencySrc from '../../../assets/images/Fluency-L.png';
import rightFluencySrc from '../../../assets/images/Fluency-R.png';
import guideSrc from '../../../assets/images/Guide.png';
import iconMeasureImage from '../../../assets/images/icon-measure-image.svg';
import iconMeasureProtractor from '../../../assets/images/icon-measure-protractor.svg';
import iconMeasureRuler from '../../../assets/images/icon-measure-ruler.svg';
import tickSrc from '../../../assets/images/icon_tick.svg';
import infoIconSrc from '../../../assets/images/info-icon.svg';
import keypadIcon from '../../../assets/images/keypad.svg';
import rightFgMountainSrc from '../../../assets/images/kraken_mountain.png';
import mathLogoSrc from '../../../assets/images/logo_mathletics.svg';
import modalCancelIconSrc from '../../../assets/images/modal-cancel-icon.svg';
import progressMountainSrc from '../../../assets/images/mountain.svg';
import pinIconSrc from '../../../assets/images/pin-icon.svg';
import leftProblemSolvingSrc from '../../../assets/images/Problem-Solving_L.png';
import rightProblemSolvingSrc from '../../../assets/images/Problem-Solving_R.png';
import progressPathSrc from '../../../assets/images/progress-path.svg';
import leftReasoningSrc from '../../../assets/images/Reasoning-L.png';
import rightReasoningSrc from '../../../assets/images/Reasoning-R.png';
import sessionTimeout from '../../../assets/images/session-timeout.svg';
import starGreySrc from '../../../assets/images/Star-grey.svg';
import starSrc from '../../../assets/images/Star.svg';
import SummaryMountain from '../../../assets/images/summary-mountain.svg';
import summitMountainSrc from '../../../assets/images/SUMMIT-FG.svg';
import swipeIconSrc from '../../../assets/images/swipe-icon.svg';
import attemptTickSrc from '../../../assets/images/tick.svg';
import leftMountainSrc from '../../../assets/images/whale_mountain.png';
import workedSolutionIcon from '../../../assets/images/worked-solution.svg';

export type AppImageTypes = keyof typeof AppImages;

export const AppImages = {
    activityGuide: activityGuideSrc,
    conceptRefreshIcon,
    progressPath: progressPathSrc,
    pinIcon: pinIconSrc,
    mathLogo: mathLogoSrc,
    progressMountain: progressMountainSrc,
    swipeIcon: swipeIconSrc,
    star: starSrc,
    starGrey: starGreySrc,
    tick: tickSrc,
    leftMountain: leftMountainSrc,
    rightFgMountain: rightFgMountainSrc,
    guide: guideSrc,
    modalCancelIcon: modalCancelIconSrc,
    attemptCross: attemptCrossSrc,
    attemptTick: attemptTickSrc,
    audioIcon: audioIconSrc,
    leftFluency: leftFluencySrc,
    rightFluency: rightFluencySrc,
    leftReasoning: leftReasoningSrc,
    rightReasoning: rightReasoningSrc,
    leftProblemSolving: leftProblemSolvingSrc,
    rightProblemSolving: rightProblemSolvingSrc,
    cloudBumpy: cloudBumpySrc,
    cloudNarrow: cloudNarrowSrc,
    summitMountain: summitMountainSrc,
    infoIcon: infoIconSrc,
    keypadIcon,
    workedSolutionIcon,
    closeIcon,
    SummaryMountain,
    chevronRight,
    fluencyFailFluffy,
    fluencyFailMountain,
    fluencyFailOcean,
    sessionTimeout,
    iconMeasureRuler,
    iconMeasureProtractor,
    iconMeasureImage
};
